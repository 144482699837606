/* eslint-disable import/first */
import React, { useState, useEffect, Suspense, useCallback } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import get from "lodash/get";

import "react-toastify/dist/ReactToastify.css";

import Login from "./Login";
import LobbyPresenter from "./PresenterScreen/LobbyPresenter";
import PresenterScreen from "./PresenterScreen/PresenterScreen";
import LobbyViewer from "./ViewerScreen/LobbyViewer";
import ViewerScreen from "./ViewerScreen/ViewerScreen";
import DemoLesson from "./DemoLesson/DemoLesson";
import Start from "./Start";
import PaymentStatus from "./Franchisees/PaymentStatus";
import NotFound from "./NotFound";
import Home from "./Home";

import ConnectTest from "./ConnectTest";
import DeviceTest from "./DeviceTest";
import SoundTest from "./SoundTest";

import { UserContext, PupilContext } from "./context/user";
import { fetchMe, fetchPupilMe } from "./api/auth";
import { postErrorLog } from "./api/log";

import CanvasDemoNext from "./CanvasDemoNext";

const CanvasDemo = React.lazy(() => import("./CanvasDemo"));
const Cabinet = React.lazy(() => import("./Cabinet/index"));
const CabinetLogin = React.lazy(() => import("./Cabinet/Login/Login"));
const CabinetRecovery = React.lazy(() => import("./Cabinet/Recovery/Recovery"));
const FrPaymentScreen = React.lazy(() => import("./Franchisees/PaymentScreen"));
const FrPaymentScreenNext = React.lazy(() =>
  import("./Franchisees/PaymentScreenNext")
);

const FrPaymentScreenNextRobokassa = React.lazy(() =>
    import("./Franchisees/PaymentScreenNextRobokassa")
);
const FrOfferScreen = React.lazy(() => import("./Franchisees/Offer"));
const FrOfferScreenRobokassa = React.lazy(() => import("./Franchisees/OfferRobokassa"));

import "./App.css";
import "./slide-content.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ViewerEndLesson from "./ViewerScreen/ViewerEndLesson/ViewerEndLesson";
import RecordLesson from "./PresenterScreen/RecordLesson";

function Promo() {
  return (
    <section className="promo-screen promo-screen_main">
      <div className="promo-screen_head">
        <div className="promo-screen_head__login">
          <Link to="/login">Войти</Link>
        </div>
      </div>
      <div className="promo-screen_message">
        Демо-стэнд будущей платформы для онлайн обучения
        <br />
        школы <a href="https://iq007.ru/">iq007.ru</a>
      </div>
      <div className="promo-screen_foot" />
    </section>
  );
}

function App() {
  const [isInited, setInitState] = useState(false);
  const [pupilIsInited, setPupilInit] = useState(false);
  const [user, setUser] = useState(null);
  const [pupil, setPupil] = useState(null);

  const errorHandlerForClient = useCallback(
    (errorMsg, url, line, column, error) => {
      const location = get(window, "location.pathname");
      const userId =
        location.indexOf("admin") > 0 ? get(user, "id") : get(pupil, "id");
      const meta = { message: errorMsg, url, line, column, error };
      postErrorLog({
        from: "client",
        location: get(window, "location.pathname"),
        errorText: JSON.stringify(meta),
        userId: userId || null,
        meta,
      })
        .then(() => {
          console.log('k@ send error data')
        })
        .catch((e) => {
          console.error("error from server", e);
        });
    },
    [user, pupil]
  );

  useEffect(() => {
    fetchMe()
      .then(setUser)
      .then(() => setInitState(true))
      .catch((error) => {
        console.error("user fetch failed", error);
      });

    fetchPupilMe()
      .then(setPupil)
      .then(() => {
        setPupilInit(true);
      })
      .catch((error) => {
        console.error("fetch pupil failed", error);
      });

    if (!window) {
      return;
    }

    const oldErrorHandler = window.onerror;
    window.onerror = errorHandlerForClient;

    return () => {
      if (!window) {
        return;
      }

      window.onerror = oldErrorHandler;
    };
  }, []);

  return (
    <Router>
      <UserContext.Provider value={{ user, setUser, userIsStopPending: isInited }}>
        <PupilContext.Provider value={{ pupil, setPupil }}>
          <Switch>
            {isInited && (
              <Route path="/login">
                <Login />
              </Route>
            )}
            <Route path="/present/:roomId">
              <PresenterScreen />
            </Route>
            <Route path="/view/:roomId">
              <ViewerScreen />
            </Route>
            <Route path="/teacher/:roomId/test">
              <LobbyPresenter />
            </Route>
            <Route path='/teacher/:roomId/record-lesson'>
              <RecordLesson />
            </Route>
            <Route path="/teacher/:roomId">
              <PresenterScreen mode="auth" />
            </Route>
            <Route path="/lesson/:roomId/test">
              <LobbyViewer />
            </Route>
            <Route exact path="/lesson/:roomId">
              <ViewerScreen mode="auth" />
            </Route>
            <Route path="/lesson/:roomId/end/:roomStrId">
              <ViewerEndLesson/>
            </Route>
            <Route path="/first/:roomId/test">
              <LobbyViewer />
            </Route>
            <Route path="/first/:lessonId">
              <DemoLesson />
            </Route>
            <Route path="/test">
              <ConnectTest />
            </Route>
            <Route path="/sound-test">
              <SoundTest />
            </Route>
            <Route path="/device-test">
              <DeviceTest />
            </Route>
            <Route path="/demo">
              <Start />
            </Route>
            <Route path="/canvas-demo">
              <Suspense fallback={<div className="loader"></div>}>
                <CanvasDemo />
              </Suspense>
            </Route>
            <Route path="/canvas-demo-next">
              <CanvasDemoNext />
            </Route>
            <Route exact path="/cabinet/login">
              <Suspense fallback={<div className="loader"></div>}>
                <CabinetLogin />
              </Suspense>
            </Route>
            <Route exact path="/cabinet/recovery/:type">
              <Suspense fallback={<div className="loader"></div>}>
                <CabinetRecovery />
              </Suspense>
            </Route>
            <Route path="/cabinet">
              {pupilIsInited && (
                <Suspense fallback={<div className="loader"></div>}>
                  <Cabinet />
                </Suspense>
              )}
            </Route>
            <Route path={"/payment/tariff-plan/:hashId/:pupilId"}>
              <Suspense fallback={<div className="loader"></div>}>
                <FrPaymentScreenNext />
              </Suspense>
            </Route>
            <Route path={"/payment/tariff-plan/:hashId"}>
              <Suspense fallback={<div className="loader"></div>}>
                <FrPaymentScreenNext />
              </Suspense>
            </Route>

            <Route path={"/payment/robokassa"}>
              <Suspense fallback={<div className="loader"></div>}>
                <FrPaymentScreenNextRobokassa/>
              </Suspense>
            </Route>

            <Route path="/:franchiseeSlug/payment/status/:paymentId">
              <PaymentStatus />
            </Route>
            <Route path="/:franchiseeSlug/payment" exact>
              <Suspense fallback={<div className="loader"></div>}>
                <FrPaymentScreen />
              </Suspense>
            </Route>
            <Route path="/:franchiseeSlug/payment/:pupilId" exact>
              <Suspense fallback={<div className="loader"></div>}>
                <FrPaymentScreen />
              </Suspense>
            </Route>
            <Route path="/:franchiseeSlug/offer">
              <Suspense fallback={<div className="loader"></div>}>
                <FrOfferScreen />
              </Suspense>
            </Route>
            <Route path="/:franchiseeSlug/offerRobokassa">
              <Suspense fallback={<div className="loader"></div>}>
                <FrOfferScreenRobokassa />
              </Suspense>
            </Route>
            <Route path="/promo">
              <Promo />
            </Route>
            <Route path="/">{isInited && <Home />}</Route>
            <Route path="/404">
              <NotFound />
            </Route>
          </Switch>
        </PupilContext.Provider>
      </UserContext.Provider>
      <ToastContainer />
    </Router>
  );
}

export default App;
